export const ENV = 'uat';
export const GENERIC_URL = {
  local: {
    api: 'http://172.16.6.207:6699/',
    socket: 'https://new-socket-staging.pgslot-api.com/',
    soketLotto: 'https://dev-lotto.pgslot-api.com/',
    newApi: 'https://auto-transaction-staging.pgslot-api.com/',
  },
  dev: {
    api: 'https://api-staging.pgslot-api.com/',
    socket: 'https://skt-staging.pgslot-api.com/',
    result: 'https://api-staging.ambprovider-hub.com/',
    soketLotto: 'https://lotto-sit.pgslot-api.com/',
    newApi: 'https://auto-transaction-staging.pgslot-api.com/',
  },
  uat: {
    api: 'https://api-uat.pgslot-api.com/',
    socket: 'https://skt-uat.pgslot-api.com/',
    result: 'https://api-uat.ambprovider-hub.com/',
    soketLotto: 'https://lotto-uat.pgslot-api.com/',
    newApi: 'https://auto-transaction-uat.pgslot-api.com/',
  },
  prod: {
    api: 'https://api-prod.pgslot-api.com/',
    socket: 'wss://skt-prod.pgslot-api.com/',
    result: 'https://api-prod.ambprovider-hub.com/',
    soketLotto: 'https://lotto-prod.pgslot-api.com/',
    newApi: 'https://auto-transaction.pgslot-api.com/',
  },
};

export const BASE_URL = GENERIC_URL[ENV].api;
export const AUTO_API_URL = GENERIC_URL[ENV].newApi;
export const SOCKET_URL = GENERIC_URL[ENV].socket;
export const SOCKET_LOTTO = GENERIC_URL[ENV].soketLotto;
export const RESULT_SPADE_GAMING_URL = GENERIC_URL[ENV].result;
export const M_GET = 'GET';
export const M_POST = 'POST';
export const M_PUT = 'PUT';
export const M_DELETE = 'DELETE';
export const PG_SECRET = 'a86f63f8722128b5c5c9a05914f70138';
export const PUBLIC_PG_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCzv7aYVAm5GPRf4r58T+quJdDzgbWwjB5ou7VAzeHNvT5vtUOztJunAQbQBTDvTZOTWZYdXTF0Tu18vY3ZMCuw0zRjjOlketMbpvP57OGhAmbL3Ont2A5fzmcc7RbiRgOOJN5FnjWMitFptUjx55Lbw84/m00Z+SdoX6A2NQDmqQIDAQAB-----END PUBLIC KEY-----';

export const FETCH_HISTORY = 'history/date';
export const GET_HISTORY = 'history/get';
export const FETCH_RANKING = 'angpow/fetch/ranking';
export const FETCH_AUTO_PROFILE = 'auto/profile';
export const FETCH_AUTO_INFO = 'auto/deposit/info';
export const SET_AUTO_BONUS = 'auto/setting/bonus';
export const CHECK_AUTO_FLOAT = 'auto/checkbankfloat';
export const CREATE_TRANSECTION = 'auto/createtransaction';
export const FETCH_TRUE = 'auto/truewallet';
export const GET_MIN_MAX = 'auto/getminmax';
export const GET_BACK_LINK = 'setting/getbacklink';
export const GET_LINK_LAUNCH_GAME = 'game/pg/link';
export const GET_QR = 'auto/initpayment';
export const GET_VERSION = 'auth/get/version';
export const VERIFY_PAYMENT = 'auto/check';
export const FETCH_VERSION_NEW_LOBBY = 'auth/fetch/version';
export const GET_EVENT_DETAIL = 'angpow/fetch/event';
export const SENT_AUTO_WITHDRAW = 'auto/withdraw';
export const CHECK_WITHDRAW = 'auto/withdraw/check';
export const FETCH_CASHBACK = 'auto/checkcashback';
export const FETCH_DETAIL_CASHBACK = 'auto/cashbackdetail';
export const GET_CASHBACK = 'auto/cashback';

export const GET_RESULT = 'spadegaming/history';

export const FETCH_LOTTO_HISTORY = 'angpow/lotto/history';
export const FETCH_ITEM = 'angpow/fetch/gift';
export const ACTIVE_ITEM = 'angpow/result/quest';
export const FECTH_POINT = 'angpow/store/fetch';
export const USE_POINT = 'angpow/store/points/use';
export const GET_USER_PROFILE = 'user/profile/v2';
export const TICKER_LEVEL = 'user/flag/webview';
export const GET_LINK_MARKETING = 'angpow/link/gamemarket';
